<!-- 低压侧 -->
<template>
  <div id="low-voltage">

    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt=""/>
        <span>低压侧</span>
      </div>
      <div class="content">
        <ul class="tabs">
          <li v-for="(item, index) in tabsData" :key="index" :class="select === index ? 'select' : ''"
              @click="switchTabs(index)">
            {{ item }}
          </li>
        </ul>
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="formData" label-width="100">
            <el-form-item label="日期" class="query-date">
              <el-date-picker placeholder="请选择日期" type="date" :append-to-body="false" value-format="yyyy-MM-dd"
                              v-model="formData.startTime"></el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="结束时间:">
              <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="formData.endTime"></el-date-picker>
            </el-form-item> -->
            <el-form-item label="回路名称">
              <el-select :popper-append-to-body="false" v-model="formData.deviceNumber" placeholder="请选择">
                <el-option v-for="(item, index) in loopList" :key="index"
                           :label="item.loopName +' ('+ item.loopNumber+ ')'"
                           :value="item.deviceNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="电力类别">
              <el-select :popper-append-to-body="false" v-model="formData.type1" placeholder="请选择"
                         @change="typeListSearch">
                <el-option label="电压" :value="1"></el-option>
                <el-option label="电流" :value="2"></el-option>
                <el-option style="" label="有功功率" :value="3"></el-option>
                <el-option label="无功功率" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <!-- <el-checkbox-group v-model="formData.typeList">
                <el-checkbox v-if="formData.type1 == 1" :label="1" name="type">A相</el-checkbox>
                <el-checkbox v-if="formData.type1 == 1" :label="2" name="type">B相</el-checkbox>
                <el-checkbox v-if="formData.type1 == 1" :label="3" name="type">c相</el-checkbox>
                <el-checkbox v-if="formData.type1 == 2" :label="4" name="type">A相</el-checkbox>
                <el-checkbox v-if="formData.type1 == 2" :label="5" name="type">B相</el-checkbox>
                <el-checkbox v-if="formData.type1 == 2" :label="6" name="type">c相</el-checkbox>
              </el-checkbox-group> -->
            </el-form-item>
            <el-form-item>
              <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
              <!-- <el-button type="primary" icon="el-icon-download">设置</el-button> -->
            </el-form-item>
          </el-form>
        </div>
        <div v-show="chartsShow==1|| chartsShow==2" id="myEcharts" class="myEcharts"></div>
        <div v-show="chartsShow==5|| chartsShow==6" id="myEchartsAV" class="myEcharts"></div>
        <div v-show="chartsShow==3||chartsShow==4" id="myEchartsTwo" class="myEcharts"></div>
        <div v-show="chartsShow==7||chartsShow==8" id="myEchartsTwoAV" class="myEcharts"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {emissionsCarbonApi} from '@/api/emissionsCarbon.js'
import {nextTick} from "vue";
import {min} from "moment";

export default {
  name: "",

  data() {
    return {
      chartsShow: 1,
      formData: {
        startTime: null,
        endTime: null,
        deviceNumber: '',
        type1: 1,
        typeList: [],
      },
      tabsData: ["日原始数据", "逐日极值数据"],
      select: 0,
      requestParam: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      stationNumber: '',
      loopList: [],

    };
  },
  created() {
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '低压侧')
    this.$store.commit("selectChildren", "电力数据");
  },

  mounted() {
    this.chartsShow = 1
    this.stationNumber = sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    emissionsCarbonApi.getLoopList({stationNumber: this.stationNumber, position: 3}).then(res => {
      this.loopList = res.data
      this.formData.deviceNumber = res.data[0].deviceNumber
      this.drawChart();
    })

  },

  methods: {
    typeListSearch(value) {
      console.log(value);
      this.formData.typeList = []
    },
    switchTabs(index) {
      this.select = index

      if (index == 0) {
        this.chartsShow = 1
        this.formData.type1 = 1
        this.drawChart()
      } else {
        this.chartsShow = 5
        this.formData.type1 = 1
        console.log("1111111111");
        this.drawChartAvg()
      }
    },
    processOnceData(res, property) {
      if (res.data[property]) {
        return {
          time: res.data[property].map(item => item.nowTime.substring(11, 16)),
          data: res.data[property].map(item => item[property])
        };
      }
      return {time: [], data: []};
    },
    processOnceDataAvg(res, property, label) {
      console.log('~~~~~~~~~~', res);
      if (res.data[property]) {
        return {
          time: res.data[property].map(item => item.nowTime.substring(11, 16)),
          data: res.data[property].map(item => item[label])
        };
      }
      return {time: [], data: []};
    },
    search() {
      console.log(this.formData);
      if (this.select == 0) {
        this.chartsShow = this.formData.type1
        if (this.formData.type1 == 1 || this.formData.type1 == 2) {
          this.drawChart()
        } else {
          this.drawChartTwo()
        }
      } else {
        this.chartsShow = this.formData.type1 + 4
        console.log("1111111111111");
        if (this.formData.type1 == 1 || this.formData.type1 == 2) {
          this.drawChartAvg()

        } else {
          this.drawChartAvgTwo()
        }
      }
    },

    drawChartAvgTwo() {
      let obj = {
        ...this.formData,
        stationNumber: this.stationNumber
      }
      if (obj.startTime) {
        obj.endTime = obj.startTime
      } else {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getLowListAvg(obj).then(res => {
        let avgUa = []
        let minUa = []
        let maxUa = []
        let avgUb = []
        let minUb = []
        let maxUb = []
        let avgUc = []
        let minUc = []
        let maxUc = []
        let avgIa = []
        let minIa = []
        let maxIa = []
        let avgIb = []
        let minIb = []
        let maxIb = []
        let avgIc = []
        let minIc = []
        let maxIc = []
        let avgPe = []
        let minPe = []
        let maxPe = []
        let avgQe = []
        let minQe = []
        let maxQe = []
        let time = []
        let min = []
        let max = []
        res.data.onceUa.forEach((item) => {
          this.$set(item, 'min', '216')
          this.$set(item, 'max', '256.8')
          min.push(item.min)
          max.push(item.max)
        })
        if (obj.type1 === 1) {
          ({time, data: avgUa} = this.processOnceDataAvg(res, 'onceUa', "avgonceUa"));
          ({time, data: minUa} = this.processOnceDataAvg(res, 'onceUa', "minonceUa"));
          ({time, data: maxUa} = this.processOnceDataAvg(res, 'onceUa', "maxonceUa"));
          ({time, data: avgUb} = this.processOnceDataAvg(res, 'onceUb', "avgonceUb"));
          ({time, data: minUb} = this.processOnceDataAvg(res, 'onceUb', "minonceUb"));
          ({time, data: maxUb} = this.processOnceDataAvg(res, 'onceUb', "maxonceUb"));
          ({time, data: avgUc} = this.processOnceDataAvg(res, 'onceUc', "avgonceUc"));
          ({time, data: minUc} = this.processOnceDataAvg(res, 'onceUc', "minonceUc"));
          ({time, data: maxUc} = this.processOnceDataAvg(res, 'onceUc', "maxonceUc"));
        } else if (obj.type1 === 2) {
          ({time, data: avgIa} = this.processOnceDataAvg(res, 'onceIa', "avgonceIa"));
          ({time, data: minIa} = this.processOnceDataAvg(res, 'onceIa', "minonceIa"));
          ({time, data: maxIa} = this.processOnceDataAvg(res, 'onceIa', "maxonceIa"));
          ({time, data: avgIb} = this.processOnceDataAvg(res, 'onceIb', "avgonceIb"));
          ({time, data: minIb} = this.processOnceDataAvg(res, 'onceIb', "minonceIb"));
          ({time, data: maxIb} = this.processOnceDataAvg(res, 'onceIb', "maxonceIb"));
          ({time, data: avgIc} = this.processOnceDataAvg(res, 'onceIc', "avgonceIc"));
          ({time, data: minIc} = this.processOnceDataAvg(res, 'onceIc', "minonceIc"));
          ({time, data: maxIc} = this.processOnceDataAvg(res, 'onceIc', "maxonceIc"));
        } else if (obj.type1 === 3) {
          ({time, data: avgPe} = this.processOnceDataAvg(res, 'oncePe', "avgoncePe"));
          ({time, data: minPe} = this.processOnceDataAvg(res, 'oncePe', "minoncePe"));
          ({time, data: maxPe} = this.processOnceDataAvg(res, 'oncePe', "maxoncePe"));
        } else {
          ({time, data: avgQe} = this.processOnceDataAvg(res, 'onceQe', "avgonceQe"));
          ({time, data: minQe} = this.processOnceDataAvg(res, 'onceQe', "minonceQe"));
          ({time, data: maxQe} = this.processOnceDataAvg(res, 'onceQe', "maxonceQe"));
        }
        console.log('~~~~~~~~', avgUa);
        console.log(minPe);
        console.log(maxPe);
        var myEcharts = echarts.init(document.getElementById("myEchartsTwoAV"));
        var option = {
          legend: {
            show: true,
            left: "center",
            top: '1%',
            icon: "circle",
            textStyle: {
              color: "#aed6ff",
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { // 启用数据视图
                show: true,
                readOnly: false, // 设置为 true 可以禁止编辑
                iconStyle: {
                  normal: {
                    color: '#aedff',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              // dataZoom: {
              //   yAxisIndex: false,
              //   iconStyle: {
              //     normal: {
              //       color: '#071B42',
              //       borderColor: '#ffffff',
              //       borderWidth: 1 
              //     }
              //   },
              // },
              // saveAsImage: {
              //   pixelRatio: 2,
              // },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          color: [],
          grid: {
            bottom: 90,
            left: "4%",
            right: "3%",
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            data: time,
            silent: false,
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              // interval: 30,
              textStyle: {
                color: "#aed6ff", // 设置 x 轴标签字体颜色
              },
            },
          },
          yAxis: {
            splitArea: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed", //
                color: "#0b2159",
              },
            },
            axisLabel: {
              color: "#aed6ff", //
            },
          },
          backgroundColor: "#071635",
          series: [],
        };
        if (res.data.onceUa || res.data.onceUb || res.data.onceUc) {
          option.series = [
            {
              name: "Ua平均值",
              type: "line",
              data: avgUa,
              symbol: "none",
            },
            {
              name: "Ua最小值",
              type: "line",
              data: minUa,
              symbol: "none",
            },
            {
              name: "Ua最大值",
              type: "line",
              data: maxUa,
              symbol: "none",
            },
            {
              name: "Ub平均值",
              type: "line",
              data: avgUb,
              symbol: "none",
            },
            {
              name: "Ub最小值",
              type: "line",
              data: minUb,
              symbol: "none",
            },
            {
              name: "Ub最大值",
              type: "line",
              data: maxUb,
              symbol: "none",
            },
            {
              name: "Uc平均值",
              type: "line",
              data: avgUc,
              symbol: "none",
            },
            {
              name: "Uc最小值",
              type: "line",
              data: minUc,
              symbol: "none",
            },
            {
              name: "Uc最大值",
              type: "line",
              data: maxUc,
              symbol: "none",
            },
            {
              name: "上限",
              type: "line",
              data: max,
              symbol: "none",
            },
            {
              name: "下限",
              type: "line",
              data: min,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34",]

        } else if (res.data.onceIa || res.data.onceIb || res.data.onceIc) {
          option.series = [
            {
              name: "Ia平均值",
              type: "line",
              data: avgIa,
              symbol: "none",
            },
            {
              name: "Ia最小值",
              type: "line",
              data: minIa,
              symbol: "none",
            },
            {
              name: "Ia最大值",
              type: "line",
              data: maxIa,
              symbol: "none",
            },
            {
              name: "Ib平均值",
              type: "line",
              data: avgIb,
              symbol: "none",
            },
            {
              name: "Ib最小值",
              type: "line",
              data: minIb,
              symbol: "none",
            },
            {
              name: "Ib最大值",
              type: "line",
              data: maxIb,
              symbol: "none",
            },
            {
              name: "Ic平均值",
              type: "line",
              data: avgIc,
              symbol: "none",
            },
            {
              name: "Ic最小值",
              type: "line",
              data: minIc,
              symbol: "none",
            },
            {
              name: "Ic最大值",
              type: "line",
              data: maxIc,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34",]
        } else if (res.data.oncePe) {
          option.series = [
            {
              name: "Pe平均值",
              type: "line",
              data: avgPe,
              symbol: "none",
            },
            {
              name: "Pe最小值",
              type: "line",
              data: minPe,
              symbol: "none",
            },
            {
              name: "Pe最大值",
              type: "line",
              data: maxPe,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34",]
        } else {
          option.series = [
            {
              name: "Qe平均值",
              type: "line",
              data: avgQe,
              symbol: "none",
            },
            {
              name: "Qe最小值",
              type: "line",
              data: minQe,
              symbol: "none",
            },
            {
              name: "Qe最大值",
              type: "line",
              data: maxQe,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34",]
        }
        myEcharts.setOption(option);
      })
    },
    drawChartAvg() {

      let obj = {
        ...this.formData,
        stationNumber: this.stationNumber
      }
      if (obj.startTime) {
        obj.endTime = obj.startTime
      } else {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getLowListAvg(obj).then(res => {
        let avgUa = []
        let minUa = []
        let maxUa = []
        let avgUb = []
        let minUb = []
        let maxUb = []
        let avgUc = []
        let minUc = []
        let maxUc = []
        let avgIa = []
        let minIa = []
        let maxIa = []
        let avgIb = []
        let minIb = []
        let maxIb = []
        let avgIc = []
        let minIc = []
        let maxIc = []
        let avgPe = []
        let minPe = []
        let maxPe = []
        let avgQe = []
        let minQe = []
        let maxQe = []
        let time = []
        let min = []
        let max = []
        res.data.onceUa.forEach((item) => {
          this.$set(item, 'min', '216')
          this.$set(item, 'max', '256.8')
          min.push(item.min)
          max.push(item.max)
        })
        if (obj.type1 === 1) {
          ({time, data: avgUa} = this.processOnceDataAvg(res, 'onceUa', "avgonceUa"));
          ({time, data: minUa} = this.processOnceDataAvg(res, 'onceUa', "minonceUa"));
          ({time, data: maxUa} = this.processOnceDataAvg(res, 'onceUa', "maxonceUa"));
          ({time, data: avgUb} = this.processOnceDataAvg(res, 'onceUb', "avgonceUb"));
          ({time, data: minUb} = this.processOnceDataAvg(res, 'onceUb', "minonceUb"));
          ({time, data: maxUb} = this.processOnceDataAvg(res, 'onceUb', "maxonceUb"));
          ({time, data: avgUc} = this.processOnceDataAvg(res, 'onceUc', "avgonceUc"));
          ({time, data: minUc} = this.processOnceDataAvg(res, 'onceUc', "minonceUc"));
          ({time, data: maxUc} = this.processOnceDataAvg(res, 'onceUc', "maxonceUc"));
        } else if (obj.type1 === 2) {
          ({time, data: avgIa} = this.processOnceDataAvg(res, 'onceIa', "avgonceIa"));
          ({time, data: minIa} = this.processOnceDataAvg(res, 'onceIa', "minonceIa"));
          ({time, data: maxIa} = this.processOnceDataAvg(res, 'onceIa', "maxonceIa"));
          ({time, data: avgIb} = this.processOnceDataAvg(res, 'onceIb', "avgonceIb"));
          ({time, data: minIb} = this.processOnceDataAvg(res, 'onceIb', "minonceIb"));
          ({time, data: maxIb} = this.processOnceDataAvg(res, 'onceIb', "maxonceIb"));
          ({time, data: avgIc} = this.processOnceDataAvg(res, 'onceIc', "avgonceIc"));
          ({time, data: minIc} = this.processOnceDataAvg(res, 'onceIc', "minonceIc"));
          ({time, data: maxIc} = this.processOnceDataAvg(res, 'onceIc', "maxonceIc"));
        } else if (obj.type1 === 3) {
          ({time, data: avgPe} = this.processOnceDataAvg(res, 'oncePe', "avgoncePe"));
          ({time, data: minPe} = this.processOnceDataAvg(res, 'oncePe', "minoncePe"));
          ({time, data: maxPe} = this.processOnceDataAvg(res, 'oncePe', "maxoncePe"));
        } else {
          ({time, data: avgQe} = this.processOnceDataAvg(res, 'onceQe', "avgonceQe"));
          ({time, data: minQe} = this.processOnceDataAvg(res, 'onceQe', "minonceQe"));
          ({time, data: maxQe} = this.processOnceDataAvg(res, 'onceQe', "maxonceQe"));
        }
        // let avgUa = [] 
        // let minUa = [] 
        // let maxUa = [] 
        // let avgUb = [] 
        // let minUb = [] 
        // let maxUb = [] 
        // let avgUc = [] 
        // let minUc = [] 
        // let maxUc = [] 
        let index = avgUa.length - 1;
        console.log(index);
        while (index >= 0 && avgUa[index] === 0) {
          index--;
        }
        if (index >= 0) {
          avgUa = avgUa.slice(0, index + 1);
          minUa = minUa.slice(0, index + 1);
          maxUa = maxUa.slice(0, index + 1);
          avgUb = avgUb.slice(0, index + 1);
          minUb = minUb.slice(0, index + 1);
          maxUb = maxUb.slice(0, index + 1);
          avgUc = avgUc.slice(0, index + 1);
          minUc = minUc.slice(0, index + 1);
          maxUc = maxUc.slice(0, index + 1);

        } else {
          avgUa = [];
          minUa = [];
          maxUa = [];
          avgUb = [];
          minUb = [];
          maxUb = [];
          avgUc = [];
          minUc = [];
          maxUc = [];
        }

        var myEcharts = echarts.init(document.getElementById("myEchartsAV"));
        var option = {
          legend: {
            show: true,
            left: "center",
            top: '1%',
            icon: "circle",
            textStyle: {
              color: "#aed6ff",
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { // 启用数据视图
                show: true,
                readOnly: false,// 设置为 true 可以禁止编辑
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              // dataZoom: {
              //   yAxisIndex: false,
              //   iconStyle: {
              //     normal: {
              //       color: '#071B42',
              //       borderColor: '#ffffff',
              //       borderWidth: 1 
              //     }
              //   },
              // },
              // saveAsImage: {
              //   pixelRatio: 2,
              // },

            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          color: [],
          grid: {
            bottom: 90,
            left: "4%",
            right: "3%",
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            data: time,
            silent: false,
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              // interval: 30,
              textStyle: {
                color: "#aed6ff", // 设置 x 轴标签字体颜色
              },
            },
          },
          yAxis: {
            scale: true,
            splitArea: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed", //
                color: "#0b2159",
              },
            },
            axisLabel: {
              color: "#aed6ff", //
            },
          },
          backgroundColor: "#071635",
          series: [],
        };
        if (res.data.onceUa || res.data.onceUb || res.data.onceUc) {

          option.series = [
            {
              name: "Ua平均值",
              type: "line",
              data: avgUa,
              symbol: "none",
            },
            {
              name: "Ua最小值",
              type: "line",
              data: minUa,
              symbol: "none",
            },
            {
              name: "Ua最大值",
              type: "line",
              data: maxUa,
              symbol: "none",
            },
            {
              name: "Ub平均值",
              type: "line",
              data: avgUb,
              symbol: "none",
            },
            {
              name: "Ub最小值",
              type: "line",
              data: minUb,
              symbol: "none",
            },
            {
              name: "Ub最大值",
              type: "line",
              data: maxUb,
              symbol: "none",
            },
            {
              name: "Uc平均值",
              type: "line",
              data: avgUc,
              symbol: "none",
            },
            {
              name: "Uc最小值",
              type: "line",
              data: minUc,
              symbol: "none",
            },
            {
              name: "Uc最大值",
              type: "line",
              data: maxUc,
              symbol: "none",
            },
            {
              name: "上限",
              type: "line",
              data: max,
              symbol: "none",
            },
            {
              name: "下限",
              type: "line",
              data: min,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34",]

        } else if (res.data.onceIa || res.data.onceIb || res.data.onceIc) {
          option.series = [
            {
              name: "Ia平均值",
              type: "line",
              data: avgIa,
              symbol: "none",
            },
            {
              name: "Ia最小值",
              type: "line",
              data: minIa,
              symbol: "none",
            },
            {
              name: "Ia最大值",
              type: "line",
              data: maxIa,
              symbol: "none",
            },
            {
              name: "Ib平均值",
              type: "line",
              data: avgIb,
              symbol: "none",
            },
            {
              name: "Ib最小值",
              type: "line",
              data: minIb,
              symbol: "none",
            },
            {
              name: "Ib最大值",
              type: "line",
              data: maxIb,
              symbol: "none",
            },
            {
              name: "Ic平均值",
              type: "line",
              data: avgIc,
              symbol: "none",
            },
            {
              name: "Ic最小值",
              type: "line",
              data: minIc,
              symbol: "none",
            },
            {
              name: "Ic最大值",
              type: "line",
              data: maxIc,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34", "#1890ff", "#2fc9cb", "#e0ab34",]
        } else if (res.data.oncePe) {
          option.series = [
            {
              name: "Pe平均值",
              type: "line",
              data: avgPe,
              symbol: "none",
            },
            {
              name: "Pe最小值",
              type: "line",
              data: minPe,
              symbol: "none",
            },
            {
              name: "Pe最大值",
              type: "line",
              data: maxPe,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34",]
        } else if (res.data.Qe) {
          console.log("11111");
          option.series = [
            {
              name: "Qe",
              type: "line",
              data: qe,
              symbol: "none",
            },
          ]
          option.color = ["#9875e3"]
        }
        myEcharts.setOption(option);

      })


    },

    drawChart() {

      let obj = {
        ...this.formData,
        stationNumber: this.stationNumber
      }
      if (obj.startTime) {
        obj.endTime = obj.startTime
      } else {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getLowList(obj).then(res => {
        let ua = []
        let ub = []
        let uc = []
        let ia = []
        let ib = []
        let ic = []
        let pe = []
        let qe = []
        let time = []
        let min = []
        let max = []
        res.data.onceUa.forEach(item => {
          this.$set(item, 'min', '216')
          this.$set(item, 'max', '256.8')
          min.push(item.min)
          max.push(item.max)
        })
        if (obj.type1 === 1) {
          ({time, data: ua} = this.processOnceData(res, "onceUa"));
          ({time, data: ub} = this.processOnceData(res, "onceUb"));
          ({time, data: uc} = this.processOnceData(res, "onceUc"));
        } else if (obj.type1 === 2) {
          ({time, data: ia} = this.processOnceData(res, "onceIa"));
          ({time, data: ib} = this.processOnceData(res, "onceIb"));
          ({time, data: ic} = this.processOnceData(res, "onceIc"));
        } else if (obj.type1 === 3) {
          ({time, data: pe} = this.processOnceData(res, "oncePe"));
        } else {
          ({time, data: qe} = this.processOnceData(res, "onceQe"));
        }
        let index = ua.length - 1;
        while (index >= 0 && ua[index] === 0) {
          index--;
        }
        if (index >= 0) {
          ua = ua.slice(0, index + 1);
          ub = ub.slice(0, index + 1);
          uc = uc.slice(0, index + 1);
        } else {
          ua = [];
          ub = [];
          uc = [];
        }
        var myEcharts = echarts.init(document.getElementById("myEcharts"));
        // if (this.chartsShow) {
        //   // 如果有，先销毁
        //   echarts.dispose(myEcharts);
        // }else{

        // }
        var option = {
          legend: {
            show: true,
            left: "center",
            top: '1%',
            icon: "circle",
            textStyle: {
              color: "#aed6ff",
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { // 启用数据视图
                show: true,
                readOnly: false, // 设置为 true 可以禁止编辑
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              // dataZoom: {
              //   yAxisIndex: false,
              //   iconStyle: {
              //     normal: {
              //       color: '#071B42',
              //       borderColor: '#ffffff',
              //       borderWidth: 1 
              //     }
              //   },
              // },
              // saveAsImage: {
              //   pixelRatio: 2,
              // },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          color: [],
          grid: {
            bottom: 90,
            left: "4%",
            right: "3%",
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            data: time,
            silent: false,
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              // interval: 30,
              textStyle: {
                color: "#aed6ff", // 设置 x 轴标签字体颜色
              },
            },
          },
          yAxis: {
            scale: true,
            splitArea: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed", //
                color: "#0b2159",
              },
            },
            axisLabel: {
              color: "#aed6ff", //
            },
          },
          backgroundColor: "#071635",
          series: [],
        };
        if (res.data.onceUa || res.data.onceUb || res.data.onceUc) {
          let seriesU = [
            {
              name: "Ua",
              type: "line",
              data: ua,
              symbol: "none",
            },
            {
              name: "Ub",
              type: "line",
              data: ub,
              symbol: "none",

            },
            {
              name: "Uc",
              type: "line",
              data: uc,
              symbol: "none",
            },
            {
              name: "上限",
              type: "line",
              data: max,
              symbol: "none",
            },
            {
              name: "下限",
              type: "line",
              data: min,
              symbol: "none",
            },
          ]
          this.$set(option, 'series', seriesU)

          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#9875e3"]
        } else if (res.data.onceIa || res.data.onceIb || res.data.onceIc) {
          option.series = [
            {
              name: "Ia",
              type: "line",
              data: ia,
              symbol: "none"
            },
            {
              name: "Ib",
              type: "line",
              data: ib,
              symbol: "none",
            },
            {
              name: "Ic",
              type: "line",
              data: ic,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#9875e3"]
        } else if (res.data.oncePe) {
          option.series = [
            {
              name: "Pe",
              type: "line",
              data: pe,
              symbol: "none"
            },
          ]
          option.color = ["#9875e3"]
          option.series = option.series.slice(0, 1);
          option.color = option.color.slice(0, 1);

        } else if (res.data.Qe) {
          option.series = [
            {
              name: "Qe",
              type: "line",
              data: qe,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff"]
        }
        myEcharts.setOption(option, true);

      })


    },
    drawChartTwo() {

      let obj = {
        ...this.formData,
        stationNumber: this.stationNumber
      }
      if (obj.startTime) {
        obj.endTime = obj.startTime
      } else {
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getLowList(obj).then(res => {
        let ua = []
        let ub = []
        let uc = []
        let ia = []
        let ib = []
        let ic = []
        let pe = []
        let qe = []
        let time = []
        let min = []
        let max = []
        res.data.onceUa.forEach(item => {
          this.$set(item, 'min', '216')
          this.$set(item, 'max', '256.8')
          min.push(item.min)
          max.push(item.max)
        })
        if (obj.type1 === 1) {
          ({time, data: ua} = this.processOnceData(res, "onceUa"));
          ({time, data: ub} = this.processOnceData(res, "onceUb"));
          ({time, data: uc} = this.processOnceData(res, "onceUc"));
        } else if (obj.type1 === 2) {
          ({time, data: ia} = this.processOnceData(res, "onceIa"));
          ({time, data: ib} = this.processOnceData(res, "onceIb"));
          ({time, data: ic} = this.processOnceData(res, "onceIc"));
        } else if (obj.type1 === 3) {
          ({time, data: pe} = this.processOnceData(res, "oncePe"));
        } else {
          ({time, data: qe} = this.processOnceData(res, "onceQe"));
        }
        let index = ua.length - 1;
        console.log(index);
        while (index >= 0 && ua[index] === 0) {
          index--;
        }
        if (index >= 0) {
          ua = ua.slice(0, index + 1);
          ub = ub.slice(0, index + 1);
          uc = uc.slice(0, index + 1);
        } else {
          ua = [];
          ub = [];
          uc = [];
        }

        var myEcharts = echarts.init(document.getElementById("myEchartsTwo"));
        // if (this.chartsShow) {
        //   // 如果有，先销毁
        //   echarts.dispose(myEcharts);
        // }else{

        // }
        var option = {
          // title: {
          //   text: `主进线柜 2023-09-20 有功功率`,
          //   textStyle: {
          //     fontSize: 20,
          //     color: "#e8f4ff",
          //   },
          //   left: "center",
          // },
          legend: {
            show: true,
            left: "center",
            top: '1%',
            icon: "circle",
            textStyle: {
              color: "#aed6ff",
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { // 启用数据视图
                show: true,
                readOnly: false, // 设置为 true 可以禁止编辑
                iconStyle: {
                  normal: {
                    color: '#071B42',
                    borderColor: '#ffffff',
                    borderWidth: 1
                  }
                },
              },
              // dataZoom: {
              //   yAxisIndex: false,
              //   iconStyle: {
              //     normal: {
              //       color: '#071B42',
              //       borderColor: '#ffffff',
              //       borderWidth: 1 
              //     }
              //   },
              // },
              // saveAsImage: {
              //   pixelRatio: 2,
              // },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          color: [],
          grid: {
            bottom: 90,
            left: "4%",
            right: "3%",
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            data: time,
            silent: false,
            splitLine: {
              show: false,
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              // interval: 30,
              textStyle: {
                color: "#aed6ff", // 设置 x 轴标签字体颜色
              },
            },
          },
          yAxis: {
            splitArea: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: "dashed", //
                color: "#0b2159",
              },
            },
            axisLabel: {
              color: "#aed6ff", //
            },
          },
          backgroundColor: "#071635",
          series: [],
        };
        if (res.data.onceUa || res.data.onceUb || res.data.onceUc) {
          console.log("11111", res);
          option.series = [
            {
              name: "Ua",
              type: "line",
              data: ua,
              symbol: "none",
            },
            {
              name: "Ub",
              type: "line",
              data: ub,
              symbol: "none",
            },
            {
              name: "Uc",
              type: "line",
              data: uc,
              symbol: "none",
            },
            {
              name: "上限",
              type: "line",
              data: max,
              symbol: "none",
            },
            {
              name: "下限",
              type: "line",
              data: min,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#9875e3"]
        } else if (res.data.onceIa || res.data.onceIb || res.data.onceIc) {
          option.series = [
            {
              name: "Ia",
              type: "line",
              data: ia,
              symbol: "none",
            },
            {
              name: "Ib",
              type: "line",
              data: ib,
              symbol: "none",
            },
            {
              name: "Ic",
              type: "line",
              data: ic,
              symbol: "none",
            },
          ]
          option.color = ["#1890ff", "#2fc9cb", "#e0ab34", "#9875e3"]
        } else if (res.data.oncePe) {
          option.series = [
            {
              name: "Pe",
              type: "line",
              data: pe,
              symbol: "none",
            },
          ]
          option.color = ["#176AD2"]
          option.series = option.series.slice(0, 1);
          option.color = option.color.slice(0, 1);

        } else if (res.data.onceQe) {
          option.series = [
            {
              name: "Qe",
              type: "line",
              data: qe,
              symbol: "none",
            },
          ]
          option.color = ["#9875e3"]
        }
        myEcharts.setOption(option);

      })


    },

  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#low-voltage {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

/* tree */
.tree :deep() .el-tree {
  width: 240px;
  color: #aed6ff;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 0 10px 21px;
  box-sizing: border-box;
}

.tree-title {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 0 21px 21px;
}

::v-deep .el-tree {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .el-tree-node {
  background: #03183f !important;
  color: #aed6ff;
}

::v-deep .is-current {
  background: #03183f !important;
  color: #ffc74a;
}

::v-deep .is-checked {
  background: #03183f !important;
  /* color: #FFC74A; */
}

::v-deep .el-tree-node__content:hover {
  background: #03183f;
  /* color: #FFC74A; */
}

::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #03183f;
}

::v-deep .el-tree-node {
  background-color: #03183f;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #03183f;
}

.main {
  width: 1280px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* tabs */
.tabs {
  width: 1336px;
  background-color: rgb(4, 25, 66);
  display: flex;
}

.tabs li {
  width: 122px;
  height: 40px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
  font-family: Microsoft YaHei;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.tabs li:hover {

  background-color: #002c6c;
  color: #ffc74a;
}

.tabs .select {
  background-color: #002c6c;
  color: #ffc74a;
}

/* 查询表单 */
.query-form {
  display: flex;
  align-items: center;
  margin: 18px 0 20px 0;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}

.form :deep() .el-input {
  width: 150px;
}

.form :deep() .el-checkbox {
  margin-right: 16px;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100% !important;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover > td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row > td.el-table__cell {
  background-color: #081f42 !important;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.myEcharts {
  width: 768px;
  height: 450px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 100px;
  height: 35px;
  background: #2142a1;
  border: 0;
  color: #fff;
}

.dialog-content {
  font-size: 16px;
  color: #ebedf1;
}

.myEcharts {
  width: 1318px;
  height: 624px;
}

#myEchartsTwo {
  width: 1318px;
  height: 624px;
}
</style>
